import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Hof.css';
import './AboutUs.css'
import './Achievements.css'
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import Footer from '../components/Footer';
import PersonComponent from './PersonComponent';

//founders
import kevin from '../components/2019-20/1667427589172kevin.jpg';
import agnel from '../components/2019-20/agnel.jpg';
import hrishkesh from '../components/2019-20/hrishikesh.jpeg';
import jebastian from '../components/2019-20/jebastian.jpg';
import prince from '../components/2019-20/prince.jpg';
import rohand from '../components/2019-20/rohand.jpg';
import shubhamk from '../components/2019-20/shubhamk.jpg';
import Founder from "./Founder";

//thest
import saurabh from '../components/22-23/THESTRAL/saurabh.jpeg';
import anubhav from '../components/22-23/THESTRAL/anubhav.jpg';
import avdhut from '../components/22-23/THESTRAL/avdhut.jpg';
import dhiraj from '../components/22-23/THESTRAL/dhiraj.jpeg';
import pratim  from '../components/22-23/THESTRAL/pratim.jpeg';
import parth from '../components/22-23/THESTRAL/parth.jpeg';
import umar from '../components/22-23/THESTRAL/umar.jpeg';
import chandresh from '../components/22-23/THESTRAL/Chandresh.jpg';
import prathamesh from '../components/22-23/THESTRAL/prathamesh.jpg';

const History = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <div className="founderscont">
            <div className="founders">
            <h1 className="foundertext">Sowing Seeds of Innovation: <br></br>From Modest Origins to Boundless Horizons.</h1>
            </div>
           </div>
           <div className="abttext">
            <p>Founded in <b>2019</b> by a visionary team of seven individuals, our aero design club emerged as a testament to the collective passion for aeronautics. Overcoming initial challenges, such as limited resources and recognition, our founders navigated the path to establish a thriving community. Through shared determination, we celebrated every milestone, building a foundation for impactful contributions to the field of aeronautical design. From humble beginnings, we emerged stronger, poised for impactful contributions to the field.</p><br/>
            </div>
            <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
           <h1 className="foundertext2">Our Founders</h1>
           <div className="foundpage">
           <div className="foundcontent">
                        <div className="foundcontentbox">
                            <div className="personcomp">
                            <Founder
                                     personImage={kevin}
                                     personName="Kevin Coutinho"
                                     personDesignation="PhD student at Arizona State University"
                                     linkedInLink="https://linkedin.com/in/kevincoutinho1899"/>
                            </div>
                        </div>
                        <div className="foundcontentbox">
                            <div className="personcomp">
                            <Founder 
                                     personImage={prince}
                                     personName="Prince Paturaj"
                                     personDesignation="Drone system engineer at lkran aerospace"
                                     linkedInLink="https://www.linkedin.com/in/prince-raja-paturaj-90b66720b"/>                                     
                            </div>
                            <div className="personcomp">
                            <Founder
                                     personImage={jebastian}
                                     personName="Jebastin Durai Nadar"
                                     personDesignation="System engineer at TCS"
                                     linkedInLink="https://www.linkedin.com/in/jebastinnadar987654"/>
                            </div>
                        </div>
                        <div className="foundcontentbox">
                        <div className="personcomp">
                        <Founder
                                     personImage={agnel}
                                     personName="Agnel Austin"
                                     personDesignation="Graduate engineer trainee - L&T hydrocarbon ltd"
                                     linkedInLink="https://www.linkedin.com/in/agnel-austin"/>
                        </div>
                        <div className="personcomp">
                        <Founder
                                     personImage={rohand}
                                     personName="Rohan D'zousa"
                                     personDesignation="Marine engineer - executive ship management"
                                     linkedInLink="https://www.linkedin.com/in/rohan-dsouza-12439252"/>
                        </div>
                        </div>
                        <div className="foundcontentbox">
                        <div className="personcomp">
                        <Founder
                                     personImage={shubhamk}
                                     personName="Shubham Kavitkar"
                                     personDesignation="Mtech at IIT ISM Dhanbad"
                                     linkedInLink="https://www.linkedin.com/in/shubham-kavitkar-815b14214"/>
                        </div>
                        <div className="personcomp">
                        <Founder
                                     personImage={hrishkesh}
                                     personName="Hrishikesh Bhosale"
                                     personDesignation="Fabrication engineer - UAV tech private limited "
                                     linkedInLink="https://www.linkedin.com/in/hrishikesh-bhosale-6b1a86167"/> 
                        </div>
                        </div>
                    </div>
            </div>
            <div className="thestcont">
            <div className="thest">
            <h1 className="foundertext">From Ground to the Sky: <br></br>Thestral's Evolution to Triumph.</h1>
            </div>
           </div>
           <div className="abttext">
            <p>In the wake of their humble beginnings, Thestral embarked on a remarkable journey. Nights in their workshop were filled with setbacks and the sight of crashing drones. Yet, their unwavering determination fueled them through each challenge. Their relentless dedication led to a triumphant moment: securing <b>All India Rank One</b> in their inaugural year of competition. This achievement stands as a testament to their perseverance and unwavering passion, marking just the beginning of their extraordinary ascent.</p><br/>
            </div>
            <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
           <h1 className="foundertext2">Team Thestral Founders</h1>
           <div className="foundpage">
           <div className="foundcontent">
                        <div className="foundcontentbox">
                        <div className="personcomp">
                            <PersonComponent 
                                     personImage={saurabh}
                                     personName="Saurabh Kaduskar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/saurabh-kaduskar-716a2a1b9"/>
                            </div>
                        </div>
                        <div className="foundcontentbox">
                        <div className="personcomp">
                            <PersonComponent 
                                     personImage={dhiraj}
                                     personName="Dhiraj Chaudhari"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={anubhav}
                                     personName="Anubhav Panda"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/anubhav-panda-996a50198"/>
                            </div>
                        </div>
                        <div className="foundcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={pratim}
                                     personName="Pratim Parab"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/pratim-parab-032210242"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={parth}
                                     personName="Parth Trivedi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/parth-trivedi-5b702b242"/>
                        </div>
                        </div>
                        <div className="foundcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={umar}
                                     personName="Umar Khan"
                                     personDesignation="Technical Member"
                                     linkedInLink="http://www.linkedin.com/in/umar-khan-737959204"/>
                        </div>
                        <div className="personcomp">
                            <PersonComponent 
                                     personImage={chandresh}
                                     personName="Chandresh Menon"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/chandresh-menon-04b67b259"/>
                            </div>
                        </div>
                        <div className="foundcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={avdhut}
                                     personName="Avdhut Shelar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/avdhut-shelar-255397207"/>                                 
                            </div>
                            <div className="personcomp">
                        <PersonComponent 
                                     personImage={prathamesh}
                                     personName="Prathamesh Patil"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/prathameshpatil19"/>
                        </div>
                        </div>
                    </div>
            </div>
            <Footer />
            </>
        )
    }
    
    export default History
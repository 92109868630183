import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const PlaneModel = () => {
  const sceneRef = useRef();

  useEffect(() => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer();

    let aspectRatio = window.innerWidth / (window.innerHeight * 0.95);

    if (window.innerWidth < 1024) {
      // Update aspect ratio based on width/height
      aspectRatio = window.innerWidth*0.8 / window.innerHeight*1.4;
      renderer.setSize(window.innerWidth*0.8 , window.innerHeight*0.8);
    } else {
      // Use the original aspect ratio
      aspectRatio = (window.innerWidth/2) / (window.innerHeight * 0.95);
      renderer.setSize(window.innerWidth/2, window.innerHeight*0.95);
    }

    // Set up camera with initial aspect ratio
    const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);

    renderer.setPixelRatio(window.devicePixelRatio);

    camera.position.set(0, 3, 1.5); 
    camera.lookAt(0, 0, 0);

    renderer.render(scene, camera);
    sceneRef.current.appendChild(renderer.domElement);

    let planemodel;
    const carbon = new THREE.TextureLoader().load('../components/carbon.jpg');
    const loader = new GLTFLoader();
    loader.load('planenew.glb', (gltf) => {
      planemodel = gltf.scene;
      planemodel.position.set(0, 0, 0);
      planemodel.scale.set(3, 3, 3);

      const blmatt = new THREE.MeshStandardMaterial({
        color : 0x000000,
        roughness: 0.5,  
        metalness: 0.5,  
      });
      
      const orange = new THREE.MeshStandardMaterial({
        color : 0Xffa31a,
        roughness: 0.2,  
        metalness: 0.5,  
      });

      const balsam = new THREE.MeshStandardMaterial({
        color : 0XF0CAA1,
        roughness: 0.8,  
        metalness: 0.5,  
      });

      const carbfibr = new THREE.MeshStandardMaterial({
        map : carbon,
        roughness: 0.2,  
        metalness: 0.6,  
      });

      const slvr = new THREE.MeshStandardMaterial({
        color : 0xc0c0c0,
        roughness: 0,  
        metalness: 0.3,  
      });
     
     planemodel.traverse((child) => {
      if (child.isMesh) {
        child.material.metalness = 0.5; 
        child.material.roughness = 0.2; 
      }
    }); 

    planemodel.getObjectByName('Outer_Casing').material = carbfibr; 
    planemodel.getObjectByName('APC_Prop_14x8').material = carbfibr;
    
    planemodel.getObjectByName('Tyre').material = blmatt; 
    planemodel.getObjectByName('Tyre_1').material = blmatt;
    planemodel.getObjectByName('Tyre_2').material = blmatt;
    planemodel.getObjectByName('Tyre_3').material = blmatt;

    planemodel.getObjectByName('START').material = slvr;
    planemodel.getObjectByName('M4_CUP_HEAD_SCREW').material = slvr;
    planemodel.getObjectByName('M4x22mm_Hex_Nutstep').material = slvr;
    planemodel.getObjectByName('M4x22mm_Hex_Nutstep_1').material = slvr;
    planemodel.getObjectByName('SPRING').material = slvr;
    planemodel.getObjectByName('Tyre_core').material = slvr;
    planemodel.getObjectByName('Tyre_core_1').material = slvr;
    planemodel.getObjectByName('lg_fuselage_plate').material = slvr;

    planemodel.getObjectByName('Back_lg_2022-23').material = slvr;
    planemodel.getObjectByName('Back_LG_Pin').material = slvr;
    planemodel.getObjectByName('Tyre_core_2').material = slvr;
    planemodel.getObjectByName('Pin_Lock').material = slvr;
    planemodel.getObjectByName('Back_LG_Pin_1').material = slvr;
    planemodel.getObjectByName('Tyre_core_3').material = slvr;
    planemodel.getObjectByName('Pin_Lock_1').material = slvr;


    planemodel.getObjectByName('Side_Aluminium_Rod').material = slvr;
    planemodel.getObjectByName('Side_Aluminium_Rod_1').material = slvr;
    planemodel.getObjectByName('Mid_aluminium_rod').material = slvr;
    planemodel.getObjectByName('Aligner').material = slvr;
    planemodel.getObjectByName('Bolt_(1)').material = slvr;
    planemodel.getObjectByName('Bolt_(1)_1').material = slvr;
    planemodel.getObjectByName('Bolt_(1)_2').material = slvr;
    planemodel.getObjectByName('Bolt_(1)_3').material = slvr;
    planemodel.getObjectByName('M8_NUT').material = slvr;
    planemodel.getObjectByName('Baut_M8').material = slvr;
    planemodel.getObjectByName('M8_NUT_1').material = slvr;
    planemodel.getObjectByName('Baut_M8_1').material = slvr;
    
    planemodel.getObjectByName('mesh_0').material = orange;
    planemodel.getObjectByName('mesh_1').material = orange;
    planemodel.getObjectByName('RIB_126').material = orange;
    planemodel.getObjectByName('part2_130').material = orange;
    planemodel.getObjectByName('part2_130_1').material = orange;
    planemodel.getObjectByName('RIB_126_-_Back').material = orange;
    planemodel.getObjectByName('Part2__120_Back').material = orange;
    planemodel.getObjectByName('mesh_7').material = orange;
    planemodel.getObjectByName('mesh_8').material = orange;
    planemodel.getObjectByName('Part2__120_Back_1').material = orange;
    planemodel.getObjectByName('FLANGE_NEW').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_1').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_2').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_3').material = orange;
    planemodel.getObjectByName('NEW_WEB').material = orange;
    planemodel.getObjectByName('NEW_WEB_1').material = orange;
    planemodel.getObjectByName('NEW_WEB_2').material = orange;
    planemodel.getObjectByName('NEW_WEB_3').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_4').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_5').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_6').material = orange;
    planemodel.getObjectByName('FLANGE_NEW_7').material = orange;
    planemodel.getObjectByName('WEB_front').material = orange;
    planemodel.getObjectByName('WEB_front_1').material = orange;
    planemodel.getObjectByName('WEB_front_2').material = orange;
    planemodel.getObjectByName('WEB_front_3').material = orange;

    planemodel.getObjectByName('037251_m_Chord_Length').material = orange;
    planemodel.getObjectByName('037251_m_Chord_Length_1').material = orange;

    planemodel.getObjectByName('tail_rib_end').material = orange;

    planemodel.getObjectByName('Part2').material = orange;
    planemodel.getObjectByName('Rudder').material = orange;
    planemodel.getObjectByName('Part2_1').material = orange;
    planemodel.getObjectByName('Rudder_1').material = orange;

    planemodel.getObjectByName('H_TAIL_4_MM_new').material = orange;
    planemodel.getObjectByName('H_TAIL_4_MM_new_1').material = orange;
    planemodel.getObjectByName('Flange_HT').material = orange;
    planemodel.getObjectByName('Flange_HT_1').material = orange;
    planemodel.getObjectByName('I_beam_new').material = orange;

    planemodel.getObjectByName('Strenght').material = balsam;
    planemodel.getObjectByName('Strenght_1').material = balsam;
    planemodel.getObjectByName('sput_top').material = balsam;
    planemodel.getObjectByName('sput_top_1').material = balsam;
    planemodel.getObjectByName('sput_top_2').material = balsam;
    planemodel.getObjectByName('sput_top_3').material = balsam;
    planemodel.getObjectByName('sput_middle').material = balsam;
    planemodel.getObjectByName('sput_middle_1').material = balsam;
    planemodel.getObjectByName('Tail_beam').material = balsam;
    planemodel.getObjectByName('Tail_beam_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_0345').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_0345_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035252').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035252_1').material = balsam;
    planemodel.getObjectByName('036269_m_Chord_Length').material = balsam;
    planemodel.getObjectByName('036269_m_Chord_Length_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035930').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035930_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_03495').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_03495_1').material = balsam;
    planemodel.getObjectByName('Aileron_Connector').material = balsam;
    planemodel.getObjectByName('Aileron_Connector_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_03493').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_03493_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035598').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035598_1').material = balsam;
    planemodel.getObjectByName('037251_m_Chord_Length').material = balsam;
    planemodel.getObjectByName('037251_m_Chord_Length_1').material = balsam;
    planemodel.getObjectByName('036635_m_Chord_Length').material = balsam;
    planemodel.getObjectByName('036635_m_Chord_Length_1').material = balsam;
    planemodel.getObjectByName('GOE448_036971').material = balsam;
    planemodel.getObjectByName('GOE448_036971_1').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035957').material = balsam;
    planemodel.getObjectByName('Airfoil_chord_035957_1').material = balsam;
    planemodel.getObjectByName('Spud_new').material = balsam;
    planemodel.getObjectByName('Spud_new_1').material = balsam;
    planemodel.getObjectByName('Aileron_new').material = balsam;
    planemodel.getObjectByName('Aileron_new_mirror').material = balsam;

    planemodel.getObjectByName('Balsa_upper_sheeting_new').material = balsam;
    planemodel.getObjectByName('Balsa_upper_sheeting_new_mirror').material = balsam;
    planemodel.getObjectByName('Balsa_bottom_sheeting_new').material = balsam;
    planemodel.getObjectByName('Balsa_bottom_sheeting_new_mirror').material = balsam;

    planemodel.getObjectByName('2mm_front_sheet_HT').material = balsam;
    planemodel.getObjectByName('SPUD').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_1').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_2').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_3').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_4').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_5').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_6').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_7').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_8').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_9').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_10').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_80_11').material = balsam;
    planemodel.getObjectByName('Backside_Spud').material = balsam;
    planemodel.getObjectByName('Elevator_HT').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_new_balsa').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_new_balsa_1').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_new_balsa_2').material = balsam;
    planemodel.getObjectByName('H_TAIL_4_MM_new_balsa_3').material = balsam;


    
      scene.add(planemodel);
    });


const pointLightColor = 0xffffff; 
const pointLightIntensity = 20;  

const pointLight = new THREE.PointLight(pointLightColor, pointLightIntensity);
pointLight.position.set(0, 2.5, 0);

const ambLightIntensity = 0.5;
const ambientLight = new THREE.AmbientLight(pointLightColor, ambLightIntensity);
scene.add(pointLight, ambientLight);

const pointLightColor2 = 0xffffff; 
const pointLightIntensity2 = 20;  
const pointLight2 = new THREE.PointLight(pointLightColor2, pointLightIntensity2);
pointLight2.position.set(0, -2.5, 0);
scene.add(pointLight2);

const pointLightColor3 = 0xffffff; 
const pointLightIntensity3 = 20;  
const pointLight3 = new THREE.PointLight(pointLightColor3, pointLightIntensity3);
pointLight3.position.set(-2.5, 1, 2.5);
scene.add(pointLight3);

const pointLightColor4 = 0xffffff; 
const pointLightIntensity4 = 20;  
const pointLight4 = new THREE.PointLight(pointLightColor4, pointLightIntensity4);
pointLight4.position.set(2.5, 1, 2.5);
scene.add(pointLight4);

window.addEventListener( 'resize', onWindowResize, false );

    function onWindowResize(){

      if (window.innerWidth < 1024) {
        // Update aspect ratio based on width/height
        camera.aspect = window.innerWidth*0.8 / window.innerHeight*1.4;
        renderer.setSize(window.innerWidth*0.8, window.innerHeight*0.8);
      } else {
        // Use the original aspect ratio
        camera.aspect = (window.innerWidth/2) / (window.innerHeight * 0.95);
        renderer.setSize(window.innerWidth/2, window.innerHeight*0.95);
      }  
    camera.updateProjectionMatrix();
    renderer.setPixelRatio(window.devicePixelRatio);
}

    camera.position.z = 5;

    const controls = new OrbitControls(camera, renderer.domElement);


    scene.background = new THREE.Color(0xffffff);

    const animate = () => {
      if (planemodel) {
      planemodel.rotation.y += 0.001; 
      planemodel.getObjectByName('APC_Prop_14x8').rotation.y += 0.05;}
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();
  }, []);

  return (
    <div ref={sceneRef} />
  );
};

export default PlaneModel;

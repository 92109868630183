import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Sponsors.css'
import Nav from "../components/Nav"

import Preloader from "../components/Preloader";
import spnSolid from '../components/sponsorSolidworks.png'
import spnAns from '../components/sponsorANSYS.png'
import spnTM from '../components/sponsorTMotor.png'
import spnSL from '../components/sponsorSmartLab.png'
import spnImp from '../components/sponsorImp.png'
import spnMtb from '../components/sponsorMatlab.png'
//import spnEmw from '../components/sponsorEmworks.png'
import spnDes from '../components/sponsorDassault.png'
import spnGog from '../components/sponsorGoogle.png'
import spnRut from '../components/sponsorRutus.png'
import spnAlt from '../components/sponsorAltair.png'
import spnAlti from '../components/sponsorAltium.png'
import Footer from '../components/Footer';

const Sponsors = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="spnsPage">
                <div className="spnsHero">
                    <div className="spnContent">
                        <br></br>
                        <h1 className="animate__animated animate__fadeInDown animate__delay-2s">OUR SPONSORS</h1>

                        <div className="spnContentBox">
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linksolid" src={spnSolid} />
                            <map name="linksolid">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.solidworks.com" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkansys" src={spnAns} />
                            <map name="linkansys">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.ansys.com/en-in" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linktmotor" src={spnTM} />
                            <map name="linktmotor">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://uav-en.tmotor.com" target="_blank"/></map>
                        </div>

                        <div className="spnContentBox">
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkdassault" src={spnDes} />
                            <map name="linkdassault">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.3ds.com" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkimperial" src={spnImp} />
                            <map name="linkimperial">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.imperial-overseas.com" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkSL" src={spnSL} />
                            <map name="linkSL">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://smartlabinn.com" target="_blank"/></map>
                        </div>

                        <div className="spnContentBox">
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkaltium" src={spnAlti} />
                            <map name="linkaltium">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.altium.com" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkaltair" src={spnAlt} />
                            <map name="linkaltair">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://altair.com" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkgoogle" src={spnGog} />
                            <map name="linkgoogle">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.google.com" target="_blank"/></map>
                        </div>

                        <div className="spnContentBox"> 
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkmatlab" src={spnMtb} />
                            <map name="linkmatlab">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://www.mathworks.com/products/matlab.html" target="_blank"/></map>
                            <img className="animate__animated animate__fadeInUp animate__delay-2s sponsorLogo ansys" alt="sponsor" useMap="#linkrutu" src={spnRut} />
                            <map name="linkrutu">
                            <area shape="rect" coords="0,0,500,500" alt="sponsor" href="https://instagram.com/rutuskitchenmumbai?igshid=NnBrc3FxM3U5bmlu" target="_blank"/></map>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Sponsors
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from "./logo.png"
import "./Nav2.css"

const Nav2 = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const handleClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const [submenuState, setSubmenuState] = useState({
        about: false,
    });

    
    const handleSubmenuClick = (submenuKey) => {
        setSubmenuState((prevState) => ({
            ...prevState,
            [submenuKey]: !prevState[submenuKey],
        }));
    };

    return (
        <>
            <nav className="navbarmusic" >
                <div className="navbar__logo animate__animated animate__fadeInUp animate__delay-1s">
                    <NavLink to="/">
                        <img src={logo} alt="" />
                    </NavLink>
                </div>
                <ul style={{ marginBottom: "0px", paddingLeft: "0" }} className={open ? "navbar__listms active" : "animate__animated animate__fadeInUp animate__delay-1s navbar__listms"}>
                    <li>
                        <NavLink className={location.pathname === "/" ? "currentPage" : ""} to="/">Home</NavLink>
                    </li>
                    <li className={`dropdown ${submenuState.about ? 'open' : ''}`}>
                        <NavLink
                            className={location.pathname === "/about" ? "currentPage" : ""}
                            onClick={() => handleSubmenuClick('about')}
                        >
                            About
                        </NavLink>
                        {submenuState.about && (
                            <div className="dropdown-content-vertical" style={{ zIndex: "99999"}}>
                                <NavLink className={location.pathname === "/about" ? "currentPage" : ""} to="/about">AboutUs</NavLink>
                                <NavLink className={location.pathname === "/mentors" ? "currentPage" : ""} to="/mentors">Mentors</NavLink>
                                <NavLink className={location.pathname === "/council" ? "currentPage" : ""} to="/council">Council</NavLink>
                                <NavLink className={location.pathname === "/history" ? "currentPage" : ""} to="/history">History</NavLink>
                            </div>
                        )}
                    </li>
                    <li>
                        <NavLink className={location.pathname === "/achievements" ? "currentPage" : ""} to="/achievements">Achievements</NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname === "/sponsors" ? "currentPage" : ""} to="/sponsors">Sponsors</NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname === "/halloffame" ? "currentPage" : ""} to="/halloffame">Hall of Fame</NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname === "/hanger" ? "currentPage" : ""} to="/hanger">Hanger</NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname === "/contactus" ? "currentPage" : ""} to="/contactus">Contact Us</NavLink>
                    </li>
                    {/* <li>
                        <NavLink className={location.pathname === "/apply" ? "applybtn currentPage" : " applybtn"} to="#">Apply Now</NavLink>
                    </li> */}
                </ul>
                <div className="navbar__menu" onClick={handleClick}>
                    <div className={open ? "navbar__menu-icon open" : "navbar__menu-icon"}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Nav2
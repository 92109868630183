import React from 'react';
import { Routes, Route } from 'react-router-dom'
import "animate.css";
import "./App.css"

//All Routes/Pages
import Home from './Pages/Home';
import Achievements from './Pages/Achievements';
import Sponsors from './Pages/Sponsors'
import HallOfFame from './Pages/HallOfFame'
import ContactUs from './Pages/ContactUs'
import AboutUs from './Pages/AboutUs';
import Hanger from './Pages/Hanger';
import Hof1920 from './Pages/Hof1920';
import Hof2021 from './Pages/Hof2021';
import Hof2122 from './Pages/Hof2122';
import Hof2223 from './Pages/Hof2223';
import Hof2324 from './Pages/Hof2324';
import Mentors from './Pages/Mentors';
import Council from './Pages/Council';
import History from './Pages/History';
import Devs from './Pages/Devs';


function App() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/achievements' element={<Achievements/>} />
        <Route exact path='/sponsors' element={<Sponsors/>} />
        <Route exact path='/halloffame' element={<HallOfFame/>} />
        <Route exact path='/hanger' element={<Hanger/>} />
        <Route exact path='/about' element={<AboutUs/>} />
        <Route exact path='/contactus' element={<ContactUs/>} />
        <Route exact path='/hof1920' element={<Hof1920/>} />
        <Route exact path='/hof2021' element={<Hof2021/>} />
        <Route exact path='/hof2122' element={<Hof2122/>} />
        <Route exact path='/hof2223' element={<Hof2223/>} />
        <Route exact path='/hof2324' element={<Hof2324/>} />
        <Route exact path='/mentors' element={<Mentors/>} />
        <Route exact path='/council' element={<Council/>} />
        <Route exact path='/history' element={<History/>} />
        <Route exact path='/devs' element={<Devs/>} />
      </Routes>
    </>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import './AboutUs.css'
import './Achievements.css'
import Nav from "../components/Nav"
import Nav2 from "../components/Nav2"
//import teamImg from "../components/aero_team.jpg";
import teamGaruda from "../components/teamGaruda.png";
import Footer from "../components/Footer";
import thestralogo from "../components/thestral-logo_no_bg.png"




const AboutUs = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <>
        {isDesktop ? <Nav/> : <Nav2/>}
        <div className="aboutuspage">
            <div className="aboutaero">
                <div className="abthero">
                <h1 className="herotext animate__animated animate__fadeInUp" style={{ width : "70%" ,marginLeft: "15%", }}>AERO FCRIT</h1>
                </div>
            </div>
            <div className="abttext">
            <p>We specialize in designing, building, and testing <b> fixed-wing UAVs </b> and <b>drones</b> , with a primary focus
                             on SAE DDC and ADDC competitions.</p>
            <p>These competitions condense a full year of aircraft development,
                              emphasizing system engineering and <b>collaboration</b> among members from different disciplines.</p>
            <p>Our goal is to provide students with a rich <b>learning</b> journey, fostering technical expertise and management skills.</p>
            <p>Each year, we aim to make significant advancements, staying at the forefront of the field and <b>contributing
                             to the future</b> of aviation through superior aircraft and valuable societal impact.</p>
            </div>
            
          
           <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
           <div className="abtgaruda">
           <div className="garudaright">
            <img src={teamGaruda} className="garudaimg" alt="" />
            </div>
            <div className="garudaleft">
<div className="abttext">
            <h1 className="teamheading"> Team Garuda</h1>
            <p>Team Garuda participates in SAEISS
                                Drone Development Challenge (DDC) formerly Known as Aero Design Challenge (ADC) which is
                                a pan-India competition, conducted
                                every year.</p>
            <p>It provides opportunities to showcase
                                extraordinary talents to conceive,
                                design and develop a prototype of a
                                fixed-wing UAV.</p>
            <p> Both Mechanical and Electrical departments contributes to the team.</p>
            </div>
            </div>    
           </div> 
           <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
            <div className="abtthestral">
                <div className="thestleft">
                    <img src={thestralogo} className="thestimg" alt="" />
                </div>
                <div className="thestright">
                    <div className="abttext">
                        <h1 className="teamheading"> Team Thestral</h1>
                        <p>Team Thestral is involved in the SAEISS Autonomous Drone Development Competition  (ADDC), an annual competition held in India.</p>
                        <p>This event serves as a platform for participants to demonstrate their exceptional skills in conceptualizing, designing, and building a drone prototype.</p>
                        <p>Mechanical, Electrical, Computer and IT departments contributes to the team</p>
                    </div>
                </div>

            </div>
        </div>
        <Footer />
        </>
        
    )
}

export default AboutUs

/*
<div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={prince}
                                     personName="Prince Paturaj"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/prince-raja-paturaj-90b66720b"/>                                     
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={jebastian}
                                     personName="Jebastin Durai Nadar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jebastinnadar987654"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={agnel}
                                     personName="Agnel Austin"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/agnel-austin"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={rohand}
                                     personName="Rohan D'zousa"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/rohan-dsouza-12439252"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={shubhamk}
                                     personName="Shubham Kavitkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/shubham-kavitkar-815b14214"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={hrishkesh}
                                     personName="Hrishikesh Bhosale"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/hrishikesh-bhosale-6b1a86167"/> 
                        </div>
                        </div>*/
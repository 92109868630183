import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import { useNavigate } from 'react-router-dom';
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import PersonComponent from './PersonComponent';
//regular
import indranil from '../components/20-21 Regular/indranil.jpg';
import jayesh from '../components/20-21 Regular/jayeshrajam.jpg';
import jebastian from '../components/20-21 Regular/jebastian.jpg';
import prince from '../components/20-21 Regular/prince.jpg';
import rohand from '../components/20-21 Regular/rohand.jpg';
import shanto from '../components/20-21 Regular/shanto.jpg';
import wilfred from '../components/20-21 Regular/wilfred.jpg';

//micro
import agnel from '../components/20-21 micro/agnel.jpg';
import cleon from '../components/20-21 micro/cleon.jpg';
import hrishikesh from '../components/20-21 micro/hrishikesh.jpeg';
import jason from '../components/20-21 micro/jason.jpg';
import shubhamk from '../components/20-21 micro/shubhamk.jpg';
import tanay from '../components/20-21 micro/tanay.jpg';
import vaishnavi from '../components/20-21 micro/vaishnavi.jpg';
import Footer from "../components/Footer";

const Hof2021 = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate('/halloffame');
      };
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
            <button style={{
                position : 'absolute',
                backgroundColor: '#011F5B',
                color: 'white',
                margin: '10px',
                padding: '10px 20px',
                borderRadius: '10px', 
                cursor: 'pointer',
                border: 'none',
      }} onClick={redirectToHomePage} >Back</button>
                <h1 className="headings">Batch of 2020-2021</h1>
                    <div className="hofcontent">
                    <h2 className="subheadings">Council Members - Regular</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={prince}
                                     personName="Prince Paturaj"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/prince-raja-paturaj-90b66720b"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={jebastian}
                                     personName="Jebastin Durai Nadar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jebastinnadar987654"/>                                   
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={rohand}
                                     personName="Rohan D'zousa"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/rohan-dsouza-12439252"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={jayesh}
                                     personName="Jayesh Rajam"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jayeshrajam/"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={shanto}
                                     personName="Shanto Mathai"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/shanto-mathai-926222195"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={wilfred}
                                     personName="Wilfred Lewis"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/wilfred-lewis-842b661b8"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={indranil}
                                     personName="Indranil Raut"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/indranil-raut99"/>
                        </div>
                        </div>
                        <h2 className="subheadings">Council Members - Micro</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={agnel}
                                     personName="Agnel Austin"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/agnel-austin"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={shubhamk}
                                     personName="Shubham Kavitkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/shubham-kavitkar-815b14214"/>                                  
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={hrishikesh}
                                     personName="Hrishikesh Bhosale"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/hrishikesh-bhosale-6b1a86167"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={tanay}
                                     personName="Tanay Iyer"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/tanay-iyer-9157a0168"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={cleon}
                                     personName="Cleon C Dias"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/cleon-dias-b009a01a3"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={jason}
                                     personName="Jason Veigas"
                                     personDesignation="Technical Member"
                                     linkedInLink="http://www.linkedin.com/in/jason-veigas-932536209"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={vaishnavi}
                                     personName="Vaishnavi Dhake"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/vaishnavi-dhake-487a00181"/>
                        </div>
                        </div>    
                    </div>
            </div>
            <Footer />
        </>
        )
    }
    
    export default Hof2021
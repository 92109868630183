import React, { useState, useEffect } from 'react';
import './Hof.css';
import Nav from '../components/Nav';
import Nav2 from "../components/Nav2"
import Preloader from '../components/Preloader';
import Footer from '../components/Footer';
import FacultyComponent from './FacultyComponent';

import smkhot from '../components/smkhot.jpg';
import father from '../components/father.jpeg';
import father2 from '../components/fr_peter.jpg';
import nilaj from '../components/nilajsir.jpg';
import aqleem from '../components/aqleems.jpg';
import afzal from '../components/afzalsir.jpg';
import sushil from '../components/sushilsir.jpg';

const Mentors = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
            <>
           {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
                <h1 className="subheadings" style={{ marginBottom: "20px", color : "#a67c00"}}>Our Patrons</h1>
                    <div className="hofcontent">
                    <div className="hofcontentbox">
                        <div className="personcomp">
                            <FacultyComponent
                                     personImage={father}
                                     personName="Fr. Saturnino Almeida"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Former Managing Director ATEC"
                                     />
                            </div>
                            <div className="personcomp">
                            <FacultyComponent
                                     personImage={father2}
                                     personName="Fr. Peter D'souza"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Managing Director"
                                     />
                            </div>
                        </div>
                        <h2 className="subheadings">Our Mentors</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <FacultyComponent
                                     personImage={smkhot}
                                     personName="Dr. S M Khot"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Professor and Principal"
                                     linkedInLink="https://www.linkedin.com/in/s-m-khot-75109837/"
                                     mailAddress="principal@fcrit.ac.in"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <FacultyComponent 
                                     personImage={nilaj}
                                     personName="Dr. Nilaj Deshmukh"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Dean (Admin & Faculty)"
                                     linkedInLink="https://www.linkedin.com/in/dr-nilaj-deshmukh-ph-d-iitb-fie-fcvs-fiiav-m-ae-s-i-31690949/"
                                     mailAddress="nilaj.deshmukh@fcrit.ac.in"/>                                     
                            </div>
                            <div className="personcomp">
                            <FacultyComponent 
                                     personImage={aqleem}
                                     personName="Dr. Aqleem Siddiqui"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Head of Department (Mechanical)"
                                     linkedInLink="https://www.linkedin.com/in/aqleem-siddiqui-60162aa1/"
                                     mailAddress="aqleems@fcrit.ac.in"/>
                            </div>
                        </div>
                        
                        <h2 className="subheadings">Faculty Advisors</h2>
                        
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <FacultyComponent 
                                     personImage={afzal}
                                     personName="Prof. Afzal Ansari"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Assistant Professor"
                                     linkedInLink=""
                                     mailAddress="afzal.ansari@fcrit.ac.in"/>                                     
                            </div>
                            <div className="personcomp">
                            <FacultyComponent 
                                     personImage={sushil}
                                     personName="Dr.Sushil Thale"
                                     personQualification="FCRIT Vashi"
                                     personDesignation="Professor and Dean (R&D) and Industry Liasoning"
                                     linkedInLink="https://www.linkedin.com/in/dr-sushil-thale-2127854/"
                                     mailAddress="sushil.thale@fcrit.ac.in"/>
                            </div>
                        </div>
                    </div> 
            </div>
            <Footer />
            </>
        )
    }
    
    export default Mentors
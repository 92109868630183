// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spnsPage {
    min-height: calc(100vh - 80px);
    background: linear-gradient(#011F5B, #03122f);
    display: flex;
    flex-direction: column;
    color: white;
}

.spnsPage .spnsHero  {
    min-height: 80vh ;
    display: flex;
    align-items: center;
}

.spnsPage .spnsHero h1 {
    text-align: center;
    margin-bottom: 30px ;
    font-size: 3rem;
}

.spnsPage .spnContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.spnsPage .spnContentBox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 20px; Add some spacing between content boxes */

}
.spnsPage .spnContentBox img{
    max-width: 25% ;
    min-width: 180px;
    height: auto;
    /* filter: drop-shadow(#000000 0px 0px 2px); 
    margin : 10px;*/
}

.spnsPage .spnContentBox .ansys{
    max-width: 23% ;
    min-width: 200px;
}

.spnsPage .spnContentBox .impSL{
    margin-left: 15%;
    max-width: 30% ;
    min-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Sponsors.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6CAA6C;IAC7C,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,gEAAgE;;AAEpE;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ;mBACe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".spnsPage {\n    min-height: calc(100vh - 80px);\n    background: linear-gradient(#011F5B, #03122f);\n    display: flex;\n    flex-direction: column;\n    color: white;\n}\n\n.spnsPage .spnsHero  {\n    min-height: 80vh ;\n    display: flex;\n    align-items: center;\n}\n\n.spnsPage .spnsHero h1 {\n    text-align: center;\n    margin-bottom: 30px ;\n    font-size: 3rem;\n}\n\n.spnsPage .spnContent{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n    height: 100%;\n}\n\n.spnsPage .spnContentBox{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    flex-wrap: wrap;\n    /* margin-bottom: 20px; Add some spacing between content boxes */\n\n}\n.spnsPage .spnContentBox img{\n    max-width: 25% ;\n    min-width: 180px;\n    height: auto;\n    /* filter: drop-shadow(#000000 0px 0px 2px); \n    margin : 10px;*/\n}\n\n.spnsPage .spnContentBox .ansys{\n    max-width: 23% ;\n    min-width: 200px;\n}\n\n.spnsPage .spnContentBox .impSL{\n    margin-left: 15%;\n    max-width: 30% ;\n    min-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Hanger.css'
import Nav from "../components/Nav"
import PlaneModel from "./PlaneModel"
import Preloader from "../components/Preloader";
import DroneModel from "./DroneModel";
import cube from '../components/cube3d.png';

const Hanger = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hanger">
                <div className="modelContainer">
                    <div className="modelDescL animate__animated animate__fadeInLeft animate__delay-1s">
                    <h1>Thestral DX1400</h1>
                    <h3>Quadcopter X</h3>
                    <h3>Weight: 1.3kg</h3>
                    <h3>Endurance: 45 minutes</h3>
                    <h3>Payload capacity: 1kg</h3>
                    </div>
                    <div className="model modelR animate__animated animate__fadeInRight animate__delay-2s">
                    <img className="cube3dL" src={cube} alt=""></img>    
                    <DroneModel />
                    </div>
                </div>
                <div className="modelContainer">
                    <div className="modelDescR animate__animated animate__fadeInRight animate__delay-1s" >
                    <h1>Garuda FWM7</h1>
                    <h3>Cruise speed : 16 m/s</h3>
                    <h3>Payload capacity of 5 kg</h3>  
                    <h3>Boom Tail Configuration</h3>     
                    </div>
                    <div className="model modelL animate__animated animate__fadeInLeft animate__delay-2s">
                    <img className="cube3dR" src={cube} alt=""></img>  
                    <PlaneModel />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hanger
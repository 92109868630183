import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import { useNavigate } from 'react-router-dom';
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import PersonComponent from './PersonComponent';
//garuda
import abhishek from '../components/22-23/GARUDA/abhishek.jpg';
import advait from '../components/22-23/GARUDA/advait.jpeg';
import aman from '../components/22-23/GARUDA/aman.jpg';
import gaurish from '../components/22-23/GARUDA/gaurish1.jpg';
import himanshu from '../components/22-23/GARUDA/himanshu.jpg';
import jayesho from '../components/22-23/GARUDA/jayeshoturkar.jpeg';
import kartik from '../components/22-23/GARUDA/kartik.jpg';
import kaustubh from '../components/22-23/GARUDA/kaustubh.jpg';
import mahika from '../components/22-23/GARUDA/mahika.jpeg';
import mayur from '../components/22-23/GARUDA/mayur.jpg';
import raziq from '../components/22-23/GARUDA/raziq.jpeg';
import sarthak from '../components/22-23/GARUDA/sarthak.jpg';
import siddhi from '../components/22-23/GARUDA/siddhi.jpeg';
import udita from '../components/22-23/GARUDA/udita.jpeg';
import vedang from '../components/22-23/GARUDA/vedang.jpg';

//thestral
import anubhav from '../components/22-23/THESTRAL/anubhav.jpg';
import avdhut from '../components/22-23/THESTRAL/avdhut.jpg';
import dhiraj from '../components/22-23/THESTRAL/dhiraj.jpeg';
import pratim  from '../components/22-23/THESTRAL/pratim.jpeg';
import saurabh from '../components/22-23/THESTRAL/saurabh.jpeg';
import parth from '../components/22-23/THESTRAL/parth.jpeg';
import umar from '../components/22-23/THESTRAL/umar.jpeg';
import soham from '../components/22-23/THESTRAL/soham.jpeg';
import mohit from '../components/22-23/THESTRAL/mohit.jpeg';
import chandresh from '../components/22-23/THESTRAL/Chandresh.jpg';
import prathamesh from '../components/22-23/THESTRAL/prathamesh.jpg';
import harshali from '../components/22-23/THESTRAL/harshali.jpg';
import surabhi from '../components/22-23/THESTRAL/surabhi.jpeg';
import Footer from '../components/Footer';

const Hof2223 = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate('/halloffame');
      };
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
            <button style={{
                position : 'absolute',
                backgroundColor: '#011F5B',
                color: 'white',
                margin: '10px',
                padding: '10px 20px',
                borderRadius: '10px', 
                cursor: 'pointer',
                border: 'none',
      }} onClick={redirectToHomePage} >Back</button>
                <h1 className="headings">Batch of 2022-2023</h1>
                    <div className="hofcontent">
                    <h2 className="subheadings">Council Members - Garuda</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={kartik}
                                     personName="Kartik Gupta"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/kartik-gupta-88b823259"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={vedang}
                                     personName="Vedang Gogate"
                                     personDesignation="Vice Captain"
                                     linkedInLink="https://www.linkedin.com/in/vedang-gogate-ab77bb245"/>                                   
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={aman}
                                     personName="Aman Sargar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/aman-sargar-92a92022a"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={abhishek}
                                     personName="Abhishek Joshi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/abhishek-joshi-772aa1244"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={advait}
                                     personName="Advait Wani"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/advait-wani-647048256"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={himanshu}
                                     personName="Himanshu Patil"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/himanshu-patil-5b0a4b243"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={jayesho}
                                     personName="Jayesh Oturkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jayesh-oturkar-16a032240"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={kaustubh}
                                     personName="Kaustubh Jambhale"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/kaustubh-jambhale-74aa61249"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={udita}
                                     personName="Udita Shetty"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/udita-shetty-02a561248"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={sarthak}
                                     personName="Sarthak Varma"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/sarthak-varma-a45b38255"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={raziq}
                                     personName="Raziq Khan"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/khanraziq"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={siddhi}
                                     personName="Siddhi Shirole"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/siddhi-shirole-b70877246"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={mahika}
                                     personName="Mahika Aigalikar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/mahika-aigalikar-24538725b"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={gaurish}
                                     personName="Gaurish Vaze"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/gaurish-vaze-1a3000280"/>  
                        </div>
                        </div>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={mayur}
                                     personName="Mayur Parab"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/mayur-parab-43b718236"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Council Members - Thestral</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={saurabh}
                                     personName="Saurabh Kaduskar"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/saurabh-kaduskar-716a2a1b9"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={dhiraj}
                                     personName="Dhiraj Chaudhari"
                                     personDesignation="Vice Captain"
                                     linkedInLink="NA"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={avdhut}
                                     personName="Avdhut Shelar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/avdhut-shelar-255397207"/>                                 
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={anubhav}
                                     personName="Anubhav Panda"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/anubhav-panda-996a50198"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={pratim}
                                     personName="Pratim Parab"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/pratim-parab-032210242"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={mohit}
                                     personName="Mohit Rajput"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/mohit-rajput-4a4ba726b"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={parth}
                                     personName="Parth Trivedi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/parth-trivedi-5b702b242"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={umar}
                                     personName="Umar Khan"
                                     personDesignation="Technical Member"
                                     linkedInLink="http://www.linkedin.com/in/umar-khan-737959204"/>
                        </div>
                        </div> 
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={soham}
                                     personName="Soham Nevgi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/soham-nevgi"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={prathamesh}
                                     personName="Prathamesh Patil"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/prathameshpatil19"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={chandresh}
                                     personName="Chandresh Menon"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/chandresh-menon-04b67b259"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={harshali}
                                     personName="Harshali Patil"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/harshali22"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={surabhi}
                                     personName="Surabhi Songirkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/surabhi-songirkar-14114725b"/>
                        </div>
                        </div>   
                    </div>
            </div>
            <Footer />
            </>
        )
    }
    
    export default Hof2223
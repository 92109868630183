import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import { useNavigate } from 'react-router-dom';
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import PersonComponent from './PersonComponent';
import kevin from '../components/2019-20/1667427589172kevin.jpg';
import agnel from '../components/2019-20/agnel.jpg';
import hrishkesh from '../components/2019-20/hrishikesh.jpeg';
import jebastian from '../components/2019-20/jebastian.jpg';
import prince from '../components/2019-20/prince.jpg';
import rohand from '../components/2019-20/rohand.jpg';
import shubhamk from '../components/2019-20/shubhamk.jpg';
import Footer from '../components/Footer';

const Hof1920 = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate('/halloffame');
      };
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
            <button style={{
                position : 'absolute',
                backgroundColor: '#011F5B',
                color: 'white',
                margin: '10px',
                padding: '10px 20px',
                borderRadius: '10px', 
                cursor: 'pointer',
                border: 'none',
      }} onClick={redirectToHomePage} >Back</button>
                <h1 className="headings">Batch of 2019-2020</h1>
                    <div className="hofcontent">
                    <h2 className="subheadings">Council Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={kevin}
                                     personName="Kevin Coutinho"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://linkedin.com/in/kevincoutinho1899"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={prince}
                                     personName="Prince Paturaj"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/prince-raja-paturaj-90b66720b"/>                                     
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={jebastian}
                                     personName="Jebastin Durai Nadar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jebastinnadar987654"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={agnel}
                                     personName="Agnel Austin"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/agnel-austin"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={rohand}
                                     personName="Rohan D'zousa"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/rohan-dsouza-12439252"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={shubhamk}
                                     personName="Shubham Kavitkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/shubham-kavitkar-815b14214"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={hrishkesh}
                                     personName="Hrishikesh Bhosale"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/hrishikesh-bhosale-6b1a86167"/> 
                        </div>
                        </div>
                    </div>
            </div>
            <Footer />
            </>
        )
    }
    
    export default Hof1920
import React from 'react';

import './FacultyComponent.css';
import linked from '../components/linkedinbw.png'
import mail from '../components/gmailbw.png'

const FacultyComponent = ({ personImage, personName, personQualification, personDesignation, linkedInLink, mailAddress }) => {
  return (
    <div className="personcomponent">
      <div className="left">
        <div className="person" style={{ backgroundImage: `url(${personImage})` }}></div>
      </div>
      <div className="main">
      <h3 className="desc pname " style={{color : "black"}}>{personName}</h3>
      <p className="desc desg" style={{color : "black" }}>{personDesignation}</p>
        <p className="desc qual" style={{color : "black" }}>{personQualification}</p>
        <div className="desc icons" >
          <a href={linkedInLink} target="_blank" rel="noopener noreferrer">
            <img className="linkedin" style={{ width: "7%" }} src={linked} alt="LinkedIn Icon" />
          </a>
          <a href={`mailto:${mailAddress}`}>
            <img className="mail" style={{ width: "7%"  }} src={mail} alt="Mail Icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FacultyComponent;

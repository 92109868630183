import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from "./logo.png";
import "./Nav.css";

const ANav = () => {
  const location = useLocation();

  const isCurrentPage = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <Navbar expand="lg" style={{ height: '70px', backgroundColor: '#011F5B' }}>
        <Navbar.Brand style={{ marginLeft : "20px"}} href="/">
          <img
            src={logo}
            width="100"
            height="50"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginRight : "20px"}} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ fontSize: '1.3rem', fontWeight: "400" }}>
            <Link to="/" className={`nav-link ${isCurrentPage('/') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Home
            </Link>
            <NavDropdown menuVariant="dark" title="About" id="basic-nav-dropdown" style={{ marginRight: '15px' }}>
              <Link to="/about" className={`dropdown-item ${isCurrentPage('/about') ? 'active' : ''}`} style={{ color: isCurrentPage('/about') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
                About Us
              </Link>
              <Link to="/mentors" className={`dropdown-item ${isCurrentPage('/mentors') ? 'active' : ''}`} style={{ color: isCurrentPage('/mentors') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
                Mentors
              </Link>
              <Link to="/council" className={`dropdown-item ${isCurrentPage('/council') ? 'active' : ''}`} style={{ color: isCurrentPage('/council') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
                Council
              </Link>
              <Link to="/history" className={`dropdown-item ${isCurrentPage('/history') ? 'active' : ''}`} style={{ color: isCurrentPage('/history') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
                History
              </Link>
            </NavDropdown>
            <Link to="/achievements" className={`nav-link ${isCurrentPage('/achievements') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/achievements') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Achievements
            </Link>
            <Link to="/sponsors" className={`nav-link ${isCurrentPage('/sponsors') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/sponsors') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Sponsors
            </Link>
            <Link to="/halloffame" className={`nav-link ${isCurrentPage('/halloffame') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/halloffame') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Hall of Fame
            </Link>
            <Link to="/hanger" className={`nav-link ${isCurrentPage('/hanger') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/hanger') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Hanger
            </Link>
            <Link to="/contactus" className={`nav-link ${isCurrentPage('/contactus') ? 'active' : ''}`} style={{ marginRight: '15px', color: isCurrentPage('/contactus') ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.5)' }}>
              Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default ANav;

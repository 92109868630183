import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import { useNavigate } from 'react-router-dom';
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import PersonComponent from './PersonComponent';
//garuda
import agnel from '../components/21-22/agnel.jpg';
import avdhut from '../components/21-22/avdhut.jpg';
import glenn from '../components/21-22/glenn.jpeg';
import jason from '../components/21-22/jason.jpg';
import jayeshrajam from '../components/21-22/jayeshrajam.jpg';
import jebastin from '../components/21-22/jebastin.jpg';
import kartik from '../components/21-22/kartik.jpg';
import rupanjon from '../components/21-22/rupanjon.jpg';
import saurabh from '../components/21-22/saurabh.jpeg';
import shreyansh from '../components/21-22/shreyansh.jpg';
import vedang from '../components/21-22/vedang.jpg';
import Footer from '../components/Footer';

const Hof2122 = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate('/halloffame');
      };
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
            <button style={{
                position : 'absolute',
                backgroundColor: '#011F5B',
                color: 'white',
                margin: '10px',
                padding: '10px 20px',
                borderRadius: '10px', 
                cursor: 'pointer',
                border: 'none',
      }} onClick={redirectToHomePage} >Back</button>
                <h1 className="headings">Batch of 2021-2022</h1>
                    <div className="hofcontent">
                    <h2 className="subheadings">Council Members -Garuda</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={jebastin}
                                     personName="Jebastin Durai Nadar"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/jebastinnadar987654"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={agnel}
                                     personName="Agnel Austin"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/agnel-austin"/>                                   
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={jason}
                                     personName="Jason Veigas"
                                     personDesignation="Technical Member"
                                     linkedInLink="http://www.linkedin.com/in/jason-veigas-932536209"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={jayeshrajam}
                                     personName="Jayesh Rajam"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/jayeshrajam/"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={glenn}
                                     personName="Glenn Tony"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/glenn-tony-958b5a104"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={rupanjon}
                                     personName="Rupanjon Das Gupta"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/rupanjon-dasgupta"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={shreyansh}
                                     personName="Shreyansh Gupta"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/shreyansh1303"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={vedang}
                                     personName="Vedang Gogate"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/vedang-gogate-ab77bb24"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={kartik}
                                     personName="Kartik Gupta"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/kartik-gupta-88b823259"/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={avdhut}
                                     personName="Avdhut Shelar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/avdhut-shelar-255397207"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={saurabh}
                                     personName="Saurabh Kaduskar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/saurabh-kaduskar-716a2a1b9"/>
                        </div>
                        </div>
                    </div>
            </div>
            <Footer />
            </>
        )
    }
    
    export default Hof2122
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px) brightness(90%);
            backdrop-filter: blur(10px) brightness(90%);
    /* background: #202020db; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.5s ease;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
}
.preloaderPropeller {
    height: 100px;
    filter: invert(1);
    /* animation: rotate 1s linear infinite; */

}


.noDisp {
    display: none;
}

.preloader-popup h2 {
    color: white;
    opacity: 1;
    text-align: center;
}

.preloader-popup.show {
    opacity: 1;
}

.preloader-popup.hide {
    opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Preloader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mDAA2C;YAA3C,2CAA2C;IAC3C,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,6BAA6B;AACjC;;;AAGA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,0CAA0C;;AAE9C;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".preloader-popup {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    backdrop-filter: blur(10px) brightness(90%);\n    /* background: #202020db; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999;\n    opacity: 0;\n    transition: opacity 0.5s ease;\n}\n\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    \n    to {\n        transform: rotate(360deg);\n    }\n}\n.preloaderPropeller {\n    height: 100px;\n    filter: invert(1);\n    /* animation: rotate 1s linear infinite; */\n\n}\n\n\n.noDisp {\n    display: none;\n}\n\n.preloader-popup h2 {\n    color: white;\n    opacity: 1;\n    text-align: center;\n}\n\n.preloader-popup.show {\n    opacity: 1;\n}\n\n.preloader-popup.hide {\n    opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

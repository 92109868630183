import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const DroneModel = () => {
  const sceneRef = useRef();

  useEffect(() => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer();

    let aspectRatio = window.innerWidth / (window.innerHeight * 0.95);

    if (window.innerWidth < 1024) {
      // Update aspect ratio based on width/height
      aspectRatio = window.innerWidth*0.8 / window.innerHeight*1.4;
      renderer.setSize(window.innerWidth*0.8 , window.innerHeight*0.8);
    } else {
      // Use the original aspect ratio
      aspectRatio = (window.innerWidth/2) / (window.innerHeight * 0.95);
      renderer.setSize(window.innerWidth/2, window.innerHeight*0.95);
    }

    // Set up camera with initial aspect ratio
    const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);

    renderer.setPixelRatio(window.devicePixelRatio);
    

    camera.position.set(-1.5, 3.5, 1.5); 
    camera.lookAt(0, 0, 0);

    renderer.render(scene, camera);
    sceneRef.current.appendChild(renderer.domElement);

    let dronemodel;
    const carbon = new THREE.TextureLoader().load('../components/carbon.jpg');
    const loader = new GLTFLoader();
    loader.load('/drone_new.gltf', (gltf) => {
      dronemodel = gltf.scene;
      dronemodel.position.set(0, 0, 0);
      dronemodel.scale.set(12, 12, 12);

      const blmatt = new THREE.MeshStandardMaterial({
        color : 0x000000,
        roughness: 0.5,  
        metalness: 0.5,  
      });
      
      const orange = new THREE.MeshStandardMaterial({
        color : 0Xffa31a,
        roughness: 0.2,  
        metalness: 0.5,  
      });

      const balsam = new THREE.MeshStandardMaterial({
        color : 0XF0CAA1,
        roughness: 0.8,  
        metalness: 0.5,  
      });

      const carbfibr = new THREE.MeshStandardMaterial({
        map : carbon,
        roughness: 0.2,  
        metalness: 0.5,  
      });

      const slvr = new THREE.MeshStandardMaterial({
        color : 0xC0C0C0,
        roughness: 0.25,  
        metalness: 0.85,  
      });
     
     dronemodel.traverse((child) => {
      if (child.isMesh) {
        child.material.metalness = 0.5; 
        child.material.roughness = 0.2; 
      }
    }); 

    //wood

    dronemodel.getObjectByName('WALL').material = balsam;
    dronemodel.getObjectByName('WALL_O').material = balsam;
    dronemodel.getObjectByName('mesh_30').material = balsam;
    dronemodel.getObjectByName('mesh_31').material = balsam;
    dronemodel.getObjectByName('WALL_O_1').material = balsam;
    dronemodel.getObjectByName('WALL_O_2').material = balsam;
    dronemodel.getObjectByName('BASE').material = balsam;
    
    //orange

    dronemodel.getObjectByName('BASE_PLATE').material = orange;
    dronemodel.getObjectByName('LOWER_PLATE').material = orange;
    dronemodel.getObjectByName('duct_new').material = orange;
    dronemodel.getObjectByName('duct_new_1').material = orange;
    dronemodel.getObjectByName('duct_new_2').material = orange;
    dronemodel.getObjectByName('duct_new_3').material = orange;

    //arms

    dronemodel.getObjectByName('RECT_ARM').material = carbfibr;
    dronemodel.getObjectByName('RECT_ARM_1').material = carbfibr;
    dronemodel.getObjectByName('RECT_ARM_2').material = carbfibr;
    dronemodel.getObjectByName('RECT_ARM_3').material = carbfibr;

    //esc

    dronemodel.getObjectByName('ESC_E800').material = carbfibr;
    dronemodel.getObjectByName('ESC_E800_1').material = carbfibr;
    dronemodel.getObjectByName('ESC_E800_2').material = carbfibr;
    dronemodel.getObjectByName('ESC_E800_3').material = carbfibr;

    //gps

    dronemodel.getObjectByName('GPS_base').material = carbfibr;
    dronemodel.getObjectByName('GPS_adapter').material = carbfibr;
    dronemodel.getObjectByName('GPS_long_rod').material = carbfibr;
    dronemodel.getObjectByName('GPS_screw_cap').material = carbfibr;
    dronemodel.getObjectByName('GPS_mount').material = carbfibr;
    dronemodel.getObjectByName('mesh_65').material = carbfibr;

    //props

    dronemodel.getObjectByName('6045_Rstep').material = carbfibr;
    dronemodel.getObjectByName('6045_Rstep_1').material = carbfibr;
    dronemodel.getObjectByName('6045step').material = carbfibr;
    dronemodel.getObjectByName('6045step_1').material = carbfibr;

    //prop screw
    //motors

    dronemodel.getObjectByName('mesh_15').material = slvr;
    dronemodel.getObjectByName('mesh_16').material = blmatt;
    dronemodel.getObjectByName('mesh_19').material = slvr;
    dronemodel.getObjectByName('mesh_20').material = blmatt;
    dronemodel.getObjectByName('mesh_53').material = slvr;
    dronemodel.getObjectByName('mesh_54').material = blmatt;
    dronemodel.getObjectByName('mesh_57').material = slvr;
    dronemodel.getObjectByName('mesh_58').material = blmatt;

    //legs

    dronemodel.getObjectByName('LANDING_GEAR').material = carbfibr;
    dronemodel.getObjectByName('LANDING_GEAR_1').material = carbfibr;
    dronemodel.getObjectByName('LANDING_GEAR_2').material = carbfibr;
    dronemodel.getObjectByName('LANDING_GEAR_2_1').material = carbfibr;

    //screws

    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-1').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am_1').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-3').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-4').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-5').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am_2').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-7').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-8').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-9').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-10').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-11').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-12').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-13').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-14').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-15').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am_3').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-18').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-20').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-21').material = slvr;
    dronemodel.getObjectByName('indented_hex_flange_head_machine_screw_am-22').material = slvr;
    
      scene.add(dronemodel);
    });


    /*
    const light = new THREE.DirectionalLight( 0xffffff, 1 );
    light.position.set( 0, 1, 0 ); //default; light shining from top
    light.castShadow = true; // default false
    scene.add( light );
    
    //Set up shadow properties for the light
    light.shadow.mapSize.width = 512; // default
    light.shadow.mapSize.height = 512; // default
    light.shadow.camera.near = 0.5; // default
    light.shadow.camera.far = 500; // default
    planemodel.castShadow = true;
    */

const pointLightColor = 0xffffff; 
const pointLightIntensity = 20;  
const pointLight = new THREE.PointLight(pointLightColor, pointLightIntensity);
pointLight.position.set(0, 2.5, 0);

const ambLightIntensity = 0.5;
const ambientLight = new THREE.AmbientLight(pointLightColor, ambLightIntensity);
scene.add(pointLight, ambientLight);

const pointLightColor2 = 0xffffff; 
const pointLightIntensity2 = 20;  
const pointLight2 = new THREE.PointLight(pointLightColor2, pointLightIntensity2);
pointLight2.position.set(0, -2.5, 0);
scene.add(pointLight2);

const pointLightColor3 = 0xffffff; 
const pointLightIntensity3 = 20;  
const pointLight3 = new THREE.PointLight(pointLightColor3, pointLightIntensity3);
pointLight3.position.set(-2.5, 1, 2.5);
scene.add(pointLight3);

const pointLightColor4 = 0xffffff; 
const pointLightIntensity4 = 20;  
const pointLight4 = new THREE.PointLight(pointLightColor4, pointLightIntensity4);
pointLight4.position.set(2.5, 1, 2.5);
scene.add(pointLight4);
// Helpers

//const lightHelper = new THREE.PointLightHelper(pointLight)
//const lightHelper2 = new THREE.PointLightHelper(pointLight2)
//const lightHelper3 = new THREE.PointLightHelper(pointLight3)
//const lightHelper4 = new THREE.PointLightHelper(pointLight4)
//const gridHelper = new THREE.GridHelper(200, 50);
//scene.add(gridHelper)
//scene.add(lightHelper,lightHelper2,lightHelper3,lightHelper4)



    camera.position.z = 5;

    const controls = new OrbitControls(camera, renderer.domElement);


    scene.background = new THREE.Color(0xffffff);

    window.addEventListener( 'resize', onWindowResize, false );

    function onWindowResize(){

      if (window.innerWidth < 1024) {
        // Update aspect ratio based on width/height
        camera.aspect = window.innerWidth*0.8 / window.innerHeight*1.4;
        renderer.setSize(window.innerWidth*0.8, window.innerHeight*0.8);
      } else {
        // Use the original aspect ratio
        camera.aspect = (window.innerWidth/2) / (window.innerHeight * 0.95);
        renderer.setSize(window.innerWidth/2, window.innerHeight*0.95);
      }  
    camera.updateProjectionMatrix();
    renderer.setPixelRatio(window.devicePixelRatio);
}

    const animate = () => {
      if (dronemodel) {
      dronemodel.rotation.y += 0.001; 
      dronemodel.getObjectByName('6045_Rstep').rotation.y -= 0.05;
      dronemodel.getObjectByName('6045_Rstep_1').rotation.y -= 0.05;
      dronemodel.getObjectByName('6045step').rotation.y += 0.05;
      dronemodel.getObjectByName('6045step_1').rotation.y += 0.05;}
      requestAnimationFrame(animate);

      controls.update();
      renderer.render(scene, camera);
    };

    animate();
  }, []);

  return (
    <div ref={sceneRef} />
  );
};

export default DroneModel;
import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import { useNavigate } from 'react-router-dom';
import './HallOfFame.css';
import Nav from "../components/Nav";
import Footer from "../components/Footer";


const HallOfFame = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const navigate = useNavigate();

  const redirectTo1920 = () => {
    navigate('/hof1920');
  };

  const redirectTo2021 = () => {
    navigate('/hof2021');
  };

  const redirectTo2122 = () => {
    navigate('/hof2122');
  };

  const redirectTo2223 = () => {
    navigate('/hof2223');
  };

  const redirectTo2324 = () => {
    navigate('/hof2324');
  };

  const [scrollAngles, setScrollAngles] = useState({
    angle1: 0,
    angle2: 120,
    angle3: 240,
  });

  const generateRandomAngles = () => {
    const angle1 = scrollAngles.angle1 + (Math.random() - 0.8) * 3;
    const angle2 = scrollAngles.angle2 + (Math.random() - 0.5) * 5;
    const angle3 = scrollAngles.angle3 + (Math.random() - 0.2) * 3;

    return { angle1, angle2, angle3 };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { angle1, angle2, angle3 } = generateRandomAngles();
      setScrollAngles({ angle1, angle2, angle3 });
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [scrollAngles]);

  const gradientStyle = {
    background: `linear-gradient(${scrollAngles.angle1}deg, rgba(75, 0, 130, .3), rgba(90, 255, 212, .3) 70.71%),
                 linear-gradient(${scrollAngles.angle2}deg, rgba(0, 148, 219, .5), rgba(0, 0, 255, .7)  70.71%),
                 linear-gradient(${scrollAngles.angle3}deg, rgba(0, 255, 0, .7), rgba(90, 255, 212, .5)   70.71%)`,
  };

    return (
        <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <div class="treediag" style={gradientStyle}>
            <h1 class="animate__animated animate__fadeInUp animate__delay-1s" style={{ width : "30%" ,marginLeft: "35%",textAlign: "center", paddingTop: "1rem" , color: "white"}}><b>TIMELINE</b></h1>
            <div class="timeline animate__animated animate__fadeInUp animate__delay-1s">

  <div class="timeline__component">
    <div class="timeline__date timeline__date--right">January 22, 2020</div>
  </div>
  <div class="timeline__middle">
    <div class="timeline__point"></div>
  </div>
  <div class="timeline__component timeline__component--bg animate__animated animate__fadeInRight animate__delay-1s">
  <button className="tlbutton" onClick={redirectTo1920} >Meet The Team</button>
    <h2 class="timeline__title">Batch of 2019-2020</h2>
    <h2 class="timeline__subtitle">
    SAEISS Aero Design Challenge
    </h2>
    <p class="timeline__paragraph">
    <b>• </b>47th overall Ranking
    </p>
    <p class="timeline__paragraph">
    <b>• </b>5th rank in technical presentation
    </p>
    <p class="timeline__paragraph">
    <b>• </b>16th rank in design report
    </p>
  </div>

  <div class="timeline__component timeline__component--bg animate__animated animate__fadeInLeft animate__delay-1s">
  <button className="tlbutton" onClick={redirectTo2021} >Meet The Team</button>
    <h2 class="timeline__title">Batch of 2020-2021</h2>
    <h2 class="timeline__subtitle">
    SAEISS Aero Design Challenge
    </h2>
    <p class="timeline__paragraph">
    <b>• </b>3rd rank in Design Report Round in Regular class nationally
    </p>
    <p class="timeline__paragraph">
    <b>• </b>Participated in Regular and micro class (Online mode)
    </p>
  </div>
  <div class="timeline__middle">
    <div class="timeline__point"></div>
  </div>
  <div class="timeline__component">
    <div class="timeline__date">February 25, 2020</div>
  </div>

  <div class="timeline__component">
    <div class="timeline__date timeline__date--right">September 3, 2022</div>
  </div>
  <div class="timeline__middle">
    <div class="timeline__point"></div>
  </div>
  <div class="timeline__component timeline__component--bg animate__animated animate__fadeInRight animate__delay-1s">
  <button className="tlbutton" onClick={redirectTo2122} >Meet The Team</button>
    <h2 class="timeline__title">Batch of 2021-2022</h2>
    <h2 class="timeline__subtitle">
    SAEISS Aero Design Challenge
    </h2>
    <p class="timeline__paragraph">
    <b>• </b>Team Gaurda Secured AIR 10 out of 80 teams participating 
    </p>
  </div>

  <div class="timeline__component timeline__component--bg animate__animated animate__fadeInLeft animate__delay-1s">
  <button className="tlbutton" onClick={redirectTo2223} >Meet The Team</button>
    <h2 class="timeline__title">Batch of 2022-2023</h2>
    <h2 class="timeline__subtitle">
    SAEISS Autonomous drone development competition (ADDC) 2023
    </h2>
    <p class="timeline__paragraph">
    <b>• </b>Team Thestral secured AIR 1 for Overall performance
    </p>
    <h2 class="timeline__subtitle">
    SAEISS Aero Design Challenge
    </h2>
    <p class="timeline__paragraph">
    <b>• </b>Team Garuda secured 6th rank for Overall performance
    </p>
    <p class="timeline__paragraph">
    <b>• </b>Team Garuda secured 3rd rank in Technical presentation 
    </p>
  </div>
  <div class="timeline__middle">
    <div class="timeline__point"></div>
  </div>
  <div class="timeline__component">
    <div class="timeline__date">July 23, 2023</div>
  </div>

  <div class="timeline__component timeline__component--bottom timeline__date--right">
    <div class="timeline__date"></div>
  </div>
  <div class="timeline__middle timeline__middle__bottom">
    <div class="timeline__point"></div>
  </div>
  <div class="timeline__component timeline__component--bottom timeline__component--bg animate__animated animate__fadeInRight animate__delay-1s">
  <button className="tlbutton" onClick={redirectTo2324} >Meet The Team</button>
    <h2 class="timeline__title">Current Batch</h2>
    <p class="timeline__paragraph">
    Team's prepping, determined for an ace performance!
    </p>
  </div>
</div>
</div>

          <Footer /> 
        </>
    )
}

export default HallOfFame;


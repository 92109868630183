// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hofpage{
    min-height: calc(100vh - 80px);
    background: #e8e8e8;
    display: flex;
    flex-direction: column;
  }
  
  .headings {
    color: #a67c00;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .subheadings {
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 30px ;
  }
  
  .hofpage .hofcontent{
    display: flex;
    flex-direction: column;
    display: flex; 
      justify-content: space-between; 
    width: 100%;
  }
  
  .hofpage .hofcontentbox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  
  }
  

  @media screen and (max-width: 550px){

    .headings {
        font-size: medium;
    }

  }`, "",{"version":3,"sources":["webpack://./src/Pages/Hof.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,aAAa;MACX,8BAA8B;IAChC,WAAW;EACb;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,mBAAmB;;EAErB;;;EAGA;;IAEE;QACI,iBAAiB;IACrB;;EAEF","sourcesContent":[".hofpage{\n    min-height: calc(100vh - 80px);\n    background: #e8e8e8;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .headings {\n    color: #a67c00;\n    text-align: center;\n    margin-top: 20px;\n    font-weight: bold;\n    margin-bottom: 0px;\n  }\n\n  .subheadings {\n    text-align: center;\n    font-weight: 700;\n    margin-top: 10px;\n    margin-bottom: 30px ;\n  }\n  \n  .hofpage .hofcontent{\n    display: flex;\n    flex-direction: column;\n    display: flex; \n      justify-content: space-between; \n    width: 100%;\n  }\n  \n  .hofpage .hofcontentbox{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-bottom: 20px;\n  \n  }\n  \n\n  @media screen and (max-width: 550px){\n\n    .headings {\n        font-size: medium;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react"
import { useNavigate } from 'react-router-dom';
import "./Footer.css";


const Footer = () => {

    const navigate = useNavigate();

    const redirectToDevTeam = () => {
        navigate('/Devs');
      };

    let footerStyle = {
        backgroundColor: "#000",
        color: "white",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        fontSize :"15px",
        fontFamily: '"Baloo Bhai 2","Poppins",sans-serif'
    }
    return (
        <>
            <section className="footer" style={footerStyle}>
            <span onClick={redirectToDevTeam} className="utext" style={{marginRight: "8px"}}> Made by </span>
                <span style={{ fontSize : "25px" , marginRight: "8px"}}> © </span>
            Aero Fcrit <span style={{color : "#07b1fb", fontSize : "18px", fontWeight : 800}}>&nbsp; ∞</span> 
            </section>

        </>
    )
}

export default Footer
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achmpage{
    min-height: calc(100vh - 70px);
    background: #f0f0f0;
    display: flex;
    flex-direction: column;

}

.achmevent {
    width: 90%;
    margin-top: 10px;
    margin-left: 5%;
    margin-bottom: 10vh;
}

.eventimg {
    width: 30%;
    float: left;
}

.eventimg img {
    width: 80%;
    margin-left: 10%;
    height: auto;
    border-radius: 10px;
}

.eventcomp {
    width: 30%;
    float: left;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 2.5rem;
    font-weight: bold;
    color: black;
}

.eventdesc {
    width: 40%;
    float: right;
    font-size: 1.05rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5rem;
}


@media screen and (max-width: 1024px){

    .eventimg {
        width: 60%;
    }

    .eventcomp {
        width: 40%;
    }

    .eventdesc {
        width: 100%;
        float: left;
    }

}

@media screen and (max-width: 550px){

    .eventimg {
        width: 100%;
    }

    .eventimg img {
        width: 100%;
    margin-left: 0%;
    }

    .eventcomp {
        width: 100%;
    }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Achievements.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;;IAEI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,WAAW;QACX,WAAW;IACf;;AAEJ;;AAEA;;IAEI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf,eAAe;IACf;;IAEA;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".achmpage{\n    min-height: calc(100vh - 70px);\n    background: #f0f0f0;\n    display: flex;\n    flex-direction: column;\n\n}\n\n.achmevent {\n    width: 90%;\n    margin-top: 10px;\n    margin-left: 5%;\n    margin-bottom: 10vh;\n}\n\n.eventimg {\n    width: 30%;\n    float: left;\n}\n\n.eventimg img {\n    width: 80%;\n    margin-left: 10%;\n    height: auto;\n    border-radius: 10px;\n}\n\n.eventcomp {\n    width: 30%;\n    float: left;\n    text-align: center;\n    font-size: 1.3rem;\n    margin-top: 2.5rem;\n    font-weight: bold;\n    color: black;\n}\n\n.eventdesc {\n    width: 40%;\n    float: right;\n    font-size: 1.05rem;\n    font-weight: 500;\n    text-align: center;\n    margin-top: 1.5rem;\n}\n\n\n@media screen and (max-width: 1024px){\n\n    .eventimg {\n        width: 60%;\n    }\n\n    .eventcomp {\n        width: 40%;\n    }\n\n    .eventdesc {\n        width: 100%;\n        float: left;\n    }\n\n}\n\n@media screen and (max-width: 550px){\n\n    .eventimg {\n        width: 100%;\n    }\n\n    .eventimg img {\n        width: 100%;\n    margin-left: 0%;\n    }\n\n    .eventcomp {\n        width: 100%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

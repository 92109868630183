// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#basic-nav-dropdown {
  color: rgba(255, 255, 255, 0.5);
}

`, "",{"version":3,"sources":["webpack://./src/components/Nav.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC","sourcesContent":["#basic-nav-dropdown {\n  color: rgba(255, 255, 255, 0.5);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

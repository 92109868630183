import React from 'react';
import './PersonComponent.css';
import linked from '../components/linkedinbw.png'
import mail from '../components/gmailbw.png'

const PersonComponent = ({ personImage, personName, personDesignation, linkedInLink, mailAddress }) => {
  return (
    <div className="personcomponent">
      <div className="left">
        <div className="person" style={{ backgroundImage: `url(${personImage})` }}></div>
      </div>
      <div className="main">
        <p className="desc desg">{personDesignation}</p>
        <h3 className="desc pname">{personName}</h3>
        <div className="desc icons" style={{ marginTop: "8px"}}>
          <a href={linkedInLink} target="_blank" rel="noopener noreferrer">
            <img className="linkedin" style={{ width: "8%"}} src={linked} alt="LinkedIn Icon" />
          </a>
          <a href={`mailto:${mailAddress}`}>
            <img className="mail" style={{ width: "8%"}} src={mail} alt="Mail Icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PersonComponent;

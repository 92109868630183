import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Hof.css';
import { useNavigate } from 'react-router-dom';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import PersonComponent from './PersonComponent';
//garuda
import kaustubh from '../components/22-23/GARUDA/kaustubh.jpg';
import mahika from '../components/22-23/GARUDA/mahika.jpeg';
import raziq from '../components/22-23/GARUDA/raziq.jpeg';
import siddhi from '../components/22-23/GARUDA/siddhi.jpeg';
 import udita from '../components/22-23/GARUDA/udita.jpeg';
 import gaurish from '../components/GARUDA23-24/gaurish2.jpg';
import abhishek from '../components/GARUDA23-24/AbhishekChavan.jpeg';
import aditya from '../components/GARUDA23-24/ADITYA_S.png';
import harshD from '../components/GARUDA23-24/harshD.jpg';
import maroof from '../components/GARUDA23-24/MAROOF.jpg';
import omkar from '../components/GARUDA23-24/OmkarGhag.jpg';
import om from '../components/GARUDA23-24/ompande.jpg';
import vaibhav from '../components/GARUDA23-24/VaibhavChavan.jpeg';
import shardul from '../components/GARUDA23-24/ShardulDalvi.jpg';
import soham from '../components/GARUDA23-24/sohamshinde.jpg';
import darshana from '../components/GARUDA23-24/DarshanaRane.jpg';
import aryanN from '../components/GARUDA23-24/aryanNand.jpeg';

//thestral 
import chandersh from '../components/THESTRAL23-24/ChandreshMenon.jpg';
import prathamesh from '../components/THESTRAL23-24/prathamesh.jpg';
import sarthak from '../components/THESTRAL23-24/sarthak.jpg';
import harshali from '../components/THESTRAL23-24/harshali.jpg';
import surabhi from '../components/THESTRAL23-24/surabhi.jpeg';
import tanishq from '../components/THESTRAL23-24/tanishq.jpeg';
import kartikeya from '../components/THESTRAL23-24/kartikeyaphale.jpg';
import aayush from '../components/THESTRAL23-24/AayushNehete.jpg';
import harsh from '../components/THESTRAL23-24/HarshNimje.jpg';
import tanmay from '../components/THESTRAL23-24/TanmaySarnobat.jpg';
import aryanB from '../components/THESTRAL23-24/aryanbhatjoshi.jpg';
//import manvi from '../components/THESTRAL23-24/ManviDesai.jpg';
import athul from '../components/THESTRAL23-24/AthulKrishna.jpg';
import arsalan from '../components/THESTRAL23-24/ArsalanDarvesh.jpg';
import manas from '../components/THESTRAL23-24/ManasMali.jpg';
import amruta from '../components/THESTRAL23-24/amruta.jpeg';
import sohamsurya from '../components/THESTRAL23-24/sohamsuryavanshi.jpg';
import Footer from '../components/Footer';


const Hof2324 = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate('/halloffame');
      };
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
            <button style={{
                position : 'absolute',
                backgroundColor: '#011F5B',
                color: 'white',
                margin: '10px',
                padding: '10px 20px',
                borderRadius: '10px', 
                cursor: 'pointer',
                border: 'none',
      }} onClick={redirectToHomePage} >Back</button>
                <h1 className="headings">Current Batch</h1>
                    <div className="hofcontent">
                    <h2 className="subheadings">Council Members - Garuda</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={kaustubh}
                                     personName="Kaustubh Jambhale"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/kaustubh-jambhale-74aa61249"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={udita}
                                     personName="Udita Shetty"
                                     personDesignation="Vice Captain"
                                     linkedInLink="https://www.linkedin.com/in/udita-shetty-02a561248"/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={raziq}
                                     personName="Raziq Khan"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/khanraziq"/>                                   
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={siddhi}
                                     personName="Siddhi Shirole"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/siddhi-shirole-b70877246"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={mahika}
                                     personName="Mahika Aigalikar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/mahika-aigalikar-24538725b"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={gaurish}
                                     personName="Gaurish Vaze"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/gaurish-vaze-1a3000280"/>  
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={harshD}
                                     personName="Harsh Doiphode"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/harsh-doiphode-59081b24b"/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={aditya}
                                     personName="Aditya S."
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/aditya-s-4378a6257"/> 
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={darshana}
                                     personName="Darshana Rane"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/darshana-rane-197990297
                                     "
                                  
/> 
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={maroof}
                                     personName="Maroof Ansari"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/maroof-husain-ansari-6a4206281
                                     "
                                  
/>  
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={vaibhav}
                                     personName="Vaibhav Chavan"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/vaibhav-chavan-a20p0347
                                     000280
                                     "
                                  
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={abhishek}
                                     personName="Abhishek C"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
                                  
/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={omkar}
                                     personName="Omkar Ghag"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/omkar-ghag-75755324b
                                     "
                                  
/> 
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={soham}
                                     personName="Soham Shinde"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
                                  
/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={shardul}
                                     personName="Shardul Dalvi"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
                                  
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={om}
                                     personName="Om Pande"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/om-pande-b96926212
                                     "
                                  
/>  
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={aryanN}
                                     personName="Aryan Nandgaonkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
                                  
/>
                        </div>
                        </div>
                        <h2 className="subheadings">Council Members - Thestral</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={chandersh}
                                     personName="Chandresh Menon"
                                     personDesignation="Team Captain"
                                     linkedInLink="https://www.linkedin.com/in/chandresh-menon-04b67b259
                                     "
/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={prathamesh}
                                     personName="Prathamesh Patil"
                                     personDesignation="Vice Captain"
                                     linkedInLink="https://www.linkedin.com/in/prathameshpatil19
                                     "
/>
                            </div>
                        </div>
                        <h2 className="subheadings">Technical Members</h2>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={sarthak}
                                     personName="Sarthak Varma"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/sarthak-varma-a45b38255
                                     "
/>                                 
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={harshali}
                                     personName="Harshali Patil"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/harshali22
                                     "
/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={surabhi}
                                     personName="Surabhi Songirkar"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/surabhi-songirkar-14114725b
                                     "
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={tanishq}
                                     personName="Tanishq Shinde"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/tanishq-shinde-7a3847263?trk=contact-info"
                                     mailAddress="mmanishq30@gmail.com"
/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={amruta}
                                     personName="Amruta Sonawane"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={kartikeya}
                                     personName="Katikeya Phale"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/kartikeya-phale-3149b9200
                                     "
/>
                        </div>
                        </div> 
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={athul}
                                     personName="Athul Krishna"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/athul-krishna-3b4820222"
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={manas}
                                     personName="Manas Mali"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/manas-mali-25022005-/
                                     "
/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={harsh}
                                     personName="Harsh Nimje"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/harsh-nimje-97906027b"
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={aayush}
                                     personName="Aayush Nehete"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA
                                     "
/>
                        </div>
                        </div>
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={arsalan}
                                     personName="Arsalan Darvesh"
                                     personDesignation="Technical Member"
                                     linkedInLink="NA"
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={tanmay}
                                     personName="Tanmay Sarnobat"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/tanmay-sarnobat-818113298
                                     "
/>
                        </div>
                        </div>  
                        <div className="hofcontentbox">
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={aryanB}
                                     personName="Aryan Bhatjoshi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/aryan-bhatjoshi-922470298
                                     "
/>
                        </div>
                        <div className="personcomp">
                        <PersonComponent 
                                     personImage={sohamsurya}
                                     personName="Soham Suryawanshi"
                                     personDesignation="Technical Member"
                                     linkedInLink="https://www.linkedin.com/in/soham-suryawanshi-a838092a9"
                                     mailAddress="soham.190204@gmail.com"
                                    
/>
                        </div>
                        </div>   
                    </div>
            </div>
            <Footer />
            </>
        )
    }
    
    export default Hof2324
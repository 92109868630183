// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.centeringdiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0.3em;
}

body::-webkit-scrollbar-track {
  /* background: linear-gradient(to bottom, #051b47, #051b47); */
  background-color: #000;

}

/* Style the scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background-color: #ffffffda;
}

/* Hover state for the scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background-color: #72bbff;

}

/* Style the scrollbar corner (optional) */
body::-webkit-scrollbar-corner {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8DAA8D;EAC9D,sBAAsB;;AAExB;;AAEA,8BAA8B;AAC9B;EACE,2BAA2B;AAC7B;;AAEA,wCAAwC;AACxC;EACE,yBAAyB;;AAE3B;;AAEA,0CAA0C;AAC1C;EACE,6BAA6B;AAC/B","sourcesContent":[".App {\n  text-align: center;\n}\n\n.centeringdiv {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\nbody {\n  overflow-x: hidden;\n}\n\nbody::-webkit-scrollbar {\n  width: 0.3em;\n}\n\nbody::-webkit-scrollbar-track {\n  /* background: linear-gradient(to bottom, #051b47, #051b47); */\n  background-color: #000;\n\n}\n\n/* Style the scrollbar thumb */\nbody::-webkit-scrollbar-thumb {\n  background-color: #ffffffda;\n}\n\n/* Hover state for the scrollbar thumb */\nbody::-webkit-scrollbar-thumb:hover {\n  background-color: #72bbff;\n\n}\n\n/* Style the scrollbar corner (optional) */\nbody::-webkit-scrollbar-corner {\n  background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import Footer from '../components/Footer';
import PersonComponent from './PersonComponent';

import saurabh from '../components/22-23/THESTRAL/saurabh.jpeg';
import kaustubh from '../components/22-23/GARUDA/kaustubh.jpg'
import raziq from '../components/22-23/GARUDA/raziq.jpeg';
import siddhi from '../components/22-23/GARUDA/siddhi.jpeg';
import udita from '../components/22-23/GARUDA/udita.jpeg';
import chandersh from '../components/THESTRAL23-24/ChandreshMenon.jpg';
import prathamesh from '../components/THESTRAL23-24/prathamesh.jpg';
import sarthak from '../components/THESTRAL23-24/sarthak.jpg';

const Council = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
                <h1 className="subheadings" style={{ marginBottom: "20px", color : "#a67c00"}}>Council Members</h1>
                    <div className="hofcontent">
                    <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={saurabh}
                                     personName="Saurabh Kaduskar"
                                     personDesignation="President"
                                     linkedInLink="https://www.linkedin.com/in/saurabh-kaduskar-716a2a1b9"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={sarthak}
                                     personName="Sarthak Varma"
                                     personDesignation="Vice President"
                                     linkedInLink="https://www.linkedin.com/in/sarthak-varma-a45b38255"/>                                 
                            </div>
                        </div>
                    <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={chandersh}
                                     personName="Chandresh Menon"
                                     personDesignation="Team Captain - Thestral"
                                     linkedInLink="https://www.linkedin.com/in/chandresh-menon-04b67b259"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={prathamesh}
                                     personName="Prathamesh Patil"
                                     personDesignation="Vice Captain - Thestral"
                                     linkedInLink="https://www.linkedin.com/in/prathameshpatil19"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={kaustubh}
                                     personName="Kaustubh Jambhale"
                                     personDesignation="Team Captain - Garuda"
                                     linkedInLink="https://www.linkedin.com/in/kaustubh-jambhale-74aa61249"/>
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={udita}
                                     personName="Udita Shetty"
                                     personDesignation="Vice Captain - Garuda"
                                     linkedInLink="https://www.linkedin.com/in/udita-shetty-02a561248"/>
                            </div>
                        </div>
                        <div className="hofcontentbox">
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={raziq}
                                     personName="Raziq Khan"
                                     personDesignation="Treasurer"
                                     linkedInLink="https://www.linkedin.com/in/khanraziq"/>                                   
                            </div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={siddhi}
                                     personName="Siddhi Shirole"
                                     personDesignation="PR Head"
                                     linkedInLink="https://www.linkedin.com/in/siddhi-shirole-b70877246"/>
                            </div>
                        </div>
                    </div> 
            </div>
            <Footer />
            </>
        )
    }
    
    export default Council
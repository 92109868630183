import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Achievements.css'
import Nav from "../components/Nav"
import teamthestral from "../components/heroimg.jpg";
import teamGaruda from "../components/heroimg5.jpg";
import Footer from "../components/Footer";
import Jason from "../components/jasonteam2.jpg"
import team2020 from "../components/Photo with faculty 2020.jpg"
import team2019 from "../components/aero1920.jpg"
const AboutUs = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <div className="achmpage">
                <h1 className="animate__animated animate__fadeInUp animate__delay-1s" style={{ textAlign: "center", fontSize: "3rem", fontWeight: "bold" ,marginTop: "1rem"}}>ACHIEVEMENTS</h1>
                <h3 className="animate__animated animate__fadeInUp animate__delay-1s" style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" ,margin: "1rem" ,marginTop: "2rem"}}><u>2022-23</u></h3>
                <div className="achmsection animate__animated animate__fadeInUp animate__delay-1s">
                    <div className="achmevent">
                    <div className="eventimg"><img src={teamthestral} alt=""></img></div>
                        <div className="eventcomp">SAE Autonomous Drone Development Competition (SAE ADDC) 2022-23</div>
                        <div className="eventdesc">Team Thestral secured <b>All India Rank 1</b> in the SAE ADDC 2022-23 in Chennai, marking a significant accomplishment in the competition. Their performance reflected their technical proficiency and collaborative efforts.</div>
                    </div>
                    </div>
                    <div className="achmsection animate__animated animate__fadeInUp animate__delay-1s">
                    <div className="achmevent" style={{ marginTop: "20px"}}>
                    <div className="eventimg"><img src={teamGaruda} alt=""></img></div>
                        <div className="eventcomp">SAE Drone Development <br /> Challenge (SAE DDC) 2022-23</div>
                        <div className="eventdesc">Team Garuda secured <b>All India Rank 3</b> in the technical presentation and <b>All India Rank 6 in overall performance</b> at the SAE DDC 2023. Their noteworthy standings underscore their proficiency in drone development.</div>
                    </div>
                </div>
                <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, marginTop: "60px", border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
                <h3 className="animate__animated animate__fadeInUp animate__delay-1s" style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" ,margin: "1rem"}}><u>2021-22</u></h3>
                <div className="achmsection animate__animated animate__fadeInUp animate__delay-1s">
                    <div className="achmevent">
                        <div className="eventimg"><img src={Jason} alt=""></img></div>
                        <div className="eventcomp">SAE Aero Design Challenge (ADC) <br />2021-22</div>
                        <div className="eventdesc">Team Garuda achieved <b>All India Rank 10</b> in the Aero Design Challenge (ADC) and secured the <b>2nd rank in Mumbai</b>. Their performance in the ADC reflects their capabilities in tackling aerodynamic design challenges. Team Garuda's achievements in both categories are indicative of their commendable efforts and skills in the field.</div>
                    </div>
                </div>
                <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0, marginTop: "60px", border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
                <h3 className="animate__animated animate__fadeInUp animate__delay-1s" style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" ,margin: "1rem"}}><u>2020-21</u></h3>
                <div className="achmsection animate__animated animate__fadeInUp animate__delay-1s">
                    <div className="achmevent">
                    <div className="eventimg"><img src={team2019} alt=""></img></div>
                        <div className="eventcomp">SAE Aero Design Challenge (ADC) <br /> 2020-21</div>
                        <div className="eventdesc">In the 2020-21 season, Team Garuda participated in both the Regular and Micro categories. Their noteworthy achievement includes securing <b>All India Rank 3</b> in the Design Report round in the Regular class at the national level. This recognition underscores Team Garuda's excellence in design and their competitive standing in the Regular category nationwide.</div>
                    </div>
                </div>
                <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0 , marginTop: "60px",border: "2px solid #000" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
                <h3 className="animate__animated animate__fadeInUp animate__delay-1s" style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" ,margin: "1rem"}}><u>2019-20</u></h3>
                <div className="achmsection animate__animated animate__fadeInUp animate__delay-1s">
                    <div className="achmevent">
                    <div className="eventimg"><img src={team2020} alt=""></img></div>
                        <div className="eventcomp">SAEISS Aero Design Challenge (ADC) <br /> 2019-20</div>
                        <div className="eventdesc">In our inaugural year, we enthusiastically participated in the SAEISS ADC 2020. Among 200 competing teams, we proudly secured the <b>5th Rank in Technical Presentation</b>, the <b>16th Rank in Design Report</b>, and achieved an <b>overall ranking of 47th</b>. These results reflect our commitment to technical excellence and innovative design, marking a promising start to our journey in the Aero Design Challenge.</div>
                    </div>
                </div>
                <div className="centeringdiv " style={{background : "#f0f0f0"}} ><hr style={{ margin: 0 , marginTop: "60px",border: "2px solid #f0f0f0" , width : "50%"}} className="animate__animated animate__fadeInUp animate__delay-1s"/></div>
            </div>
            <Footer />
        </>
    )
}

export default AboutUs


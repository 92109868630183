import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import './Hof.css';
import Nav from '../components/Nav';
import Preloader from '../components/Preloader';
import Footer from '../components/Footer';
import PersonComponent from './PersonComponent';

import harshali from '../components/THESTRAL23-24/harshali.jpg';
import tanishq from '../components/THESTRAL23-24/tanishq.jpeg';


const Devs = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
            <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="hofpage">
                <h1 className="subheadings" style={{ marginBottom: "20px", color : "#a67c00"}}>Dev Team</h1>
                    <div className="hofcontent">
                    <div className="hofcontentbox">
                    <div className="personcomp">
                        <PersonComponent 
                                     personImage={tanishq}
                                     personName="Tanishq Shinde"
                                     personDesignation="Developer"
                                     linkedInLink="https://www.linkedin.com/in/tanishq-shinde-7a3847263?trk=contact-info"
                                     mailAddress="mmanishq30@gmail.com"/></div>
                            <div className="personcomp">
                            <PersonComponent 
                                     personImage={harshali}
                                     personName="Harshali Patil"
                                     personDesignation="Developer"
                                     linkedInLink="https://www.linkedin.com/in/harshali22"/></div>
                        </div>
                    </div> 
            </div>
            <Footer />
            </>
        )
    }
    
    export default Devs
import React, { useState, useEffect } from 'react';
import Nav2 from "../components/Nav2"
import Nav from "../components/Nav";
import './ContactUs.css';
import insta from '../components/instabw.png';
import linkin from '../components/linkedinbw.png';
import youtube from '../components/youtubebw.png';
import facebook from '../components/facebookbw.png';
import mail from '../components/gmailbw.png';
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import Footer from "../components/Footer";
const ContactUs = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const form = useRef();
  
  const handleSubmit = (e) => {
    
    e.preventDefault();

    emailjs.sendForm("service_huhymqd", 'template_5n37fqp', form.current, 'K0DmHY3RW1jlINCh2')
      .then((result) => {
          console.log(result.text);
          alert("Mail Send");
         }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <>
    {isDesktop ? <Nav/> : <Nav2/>}
    <div className="contact-banner">
      <h1>Contact Aero</h1>
    </div> 
    <div className="contact-content">
    <div className="contact-us-form">
      <h2>Let's Start a Conversation</h2>
      <form ref={form} onSubmit={handleSubmit}>
        <div>
          <label >Name</label>
          <input
            type="text"
            id="username"
            name="name"
            required
          />
        </div>
        <div>
          <label >Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
          />
        </div>
        <div>
            <label >Message:</label>
            <textarea
              id="message"
              name="message"
            
              required
            />
          </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
    <div className="contact-social">
    <div className="follow-us-container">
      <h2 style={{ color: 'white' }}>Follow us on:</h2>
    </div>
    <div className="social-links">
    <a href="https://www.linkedin.com/company/aero-fcrit/" target="_blank" rel="noreferrer"><img src={linkin} alt=""></img></a>
    <a href="mailto:aerofcrit@gmail.com" target="_blank" rel="noreferrer"><img src={mail} alt=""></img></a>
    <a href="https://m.facebook.com/people/Aero-Fcrit" target="_blank" rel="noreferrer"><img src={facebook} alt=""></img></a>  
    <a href="https://instagram.com/aero_fcrit" target="_blank" rel="noreferrer"><img src={insta} alt=""></img></a>
    <a href="https://youtube.com/@aerofcrit" target="_blank" rel="noreferrer"><img src={youtube} alt=""></img></a>
    </div>
    </div>
    
     </div>
    <Footer />
  </>
  )
}

export default ContactUs;

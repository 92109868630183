import React, { useState, useEffect } from "react";
import Nav from "../components/Nav";
import Nav2 from "../components/Nav2"
import "./Home.css";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import aero from "../components/logo.png";
import phn from "../components/phone-call.png";
import qr from "../components/qr.png";


const Home = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const [emojiIndex, setEmojiIndex] = useState(0);
    const emojis = ["✈️", "", "🚀", "", "🛩️", "", "🛫", "", "🛬", "", "✈️️️", "", "🚁", "", "🪂", "", "💯", "", "💡", ""];

    const [scrollAngles, setScrollAngles] = useState({
        angle1: 0,
        angle2: 120,
        angle3: 240,
      });
    
      const generateRandomAngles = () => {
        const angle1 = scrollAngles.angle1 + (Math.random() - 0.8) * 3;
        const angle2 = scrollAngles.angle2 + (Math.random() - 0.5) * 5;
        const angle3 = scrollAngles.angle3 + (Math.random() - 0.2) * 3;
    
        return { angle1, angle2, angle3 };
      };
    
      useEffect(() => {
        const interval = setInterval(() => {
          const { angle1, angle2, angle3 } = generateRandomAngles();
          setScrollAngles({ angle1, angle2, angle3 });
        }, 25);
    
        return () => {
          clearInterval(interval);
        };
      }, [scrollAngles]);
    
      const gradientStyle = {
        background: `linear-gradient(${scrollAngles.angle1}deg, rgba(75, 0, 130, .3), rgba(90, 255, 212, .3) 70.71%),
                     linear-gradient(${scrollAngles.angle2}deg, rgba(0, 148, 219, .5), rgba(0, 0, 255, .7)  70.71%),
                     linear-gradient(${scrollAngles.angle3}deg, rgba(0, 255, 0, .7), rgba(90, 255, 212, .5)   70.71%)`,
      };

    useEffect(() => {
        const interval = setInterval(() => {
            setEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isDesktop ? <Nav/> : <Nav2/>}
            <Preloader />
            <div className="homePage" style={{ zIndex: "-10"}}>
                <div className="hero" style={gradientStyle}>
                    <div className="homeLeft">
                        <div className="homeLeftContent">
                            <h1 className="animate__animated animate__fadeInLeft animate__delay-2s gradientText">
                                AERO FCRIT
                            </h1>
                            <h3 className="animate__animated animate__fadeInLeft animate__delay-2s subText">
                                A Place Where <br/>Innovation <br/>Meets Aviation
                            </h3>
                            <div className="cursorEffect"></div>
                            <h2 className="animate__animated animate__fadeInLeft animate__delay-2s">
                                {emojis[emojiIndex]}
                                <span className="cursor"></span>
                            </h2>
                        </div>
                    </div>
                    <div className="homeRight animate__animated animate__fadeInRight animate__delay-2s">
                        <div className="teamheroimgs"></div>    
                    </div>
                    <div className="stripe animate__animated animate__fadeInRight animate__delay-2s"></div>
                </div>
                <div className="centeringdiv" style={{background : "#062668"}} ><hr style={{ margin: 0, border: "2px solid #fff" , width : "50%"}} /></div>
                <div className="visionmissionSection vm1">
                    <span className="visionvert animate__animated animate__fadeInUp animate__delay-3s">NOISIΛ</span>
                    <div className="visionArea animate__animated animate__fadeInLeft animate__delay-3s ">
                        <div className="vision VMText" >
                        <h3 style={{color : "black"}}>To actively seek and amass deep knowledge in aviation and diligently employ this acquired expertise, allowing us to contribute meaningfully make a versatile and far-reaching impact across various domains.</h3>
                        </div>
                    </div>
                </div>
                <div className="visionmissionSection vm2">
                    <span className="missionvert animate__animated animate__fadeInDown animate__delay-3s">MISSION</span>
                    <div className="missionArea animate__animated animate__fadeInRight animate__delay-3s ">
                        <div className="mission VMText" >
                        <h3>To partake in prestigious national and international level competitions, pioneer research and development initiatives, and proficiently oversee substantial industrial projects.</h3>
                        </div>    
                    </div>
                </div>
                <div className="centeringdiv" style={{background : "#062668"}} ><hr style={{ margin: 0, border: "2px solid #fff" , width : "50%"}} /></div>
                <div className="keeto" >
                    <img className="aeroicon"src={aero} alt=""></img>
                    <div className="keetocontent">
                        <div className="keetoleft keetodiv">
                            <a href="https://www.instagram.com/p/Cx2t0_DoH0t/?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer" alt="">
                            <img alt=""></img></a>
                        </div>
                        <div className="keetoright keetodiv">
                            <h1 style={{ marginTop : "2.3rem"}}>THE KETTO FUNDRAISER</h1>
                            <h1> IS LIVE NOW !</h1>
                            <h3> Scan the QR to support us...</h3>
                            <a href="https://www.ketto.org/fundraiser/aerofcrit-help-us-to-reach-the-sky?utm_medium=nativeShare&utm_content=57217101880b34125b686802a42a3ba9&shby=1&utm_term=&utm_source=campaigner_ct&utm_campaign=aerofcrit-help-us-to-reach-the-sky" target="_blank" rel="noreferrer" alt="">
                            <img src={qr} alt=""></img></a>
                        </div>
                    </div>
                </div>
                <div className="centeringdiv" style={{background : "#062668"}} ><hr style={{ margin: 0, border: "2px solid #fff" , width : "50%"}} /></div>
                <div className="mapSection ">
                    <h1>Meet & Greet Us @</h1>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                title="aeroMap"
                                className="gmap_iframe"
                                width="100%"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=658&amp;height=320&amp;hl=en&amp;q=Fcrit&amp;t=p&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                        </div>
                    </div>
                    <div className="socialLinks">
                        <h2>Connect with Us : </h2>
                        <div className="phncontainer">
                        <div className="phnno">
                            <a href="tel:9930928676"><img className="phnsymb" src={phn} alt=""></img><p className="numbr">+91 99309 28676</p></a>
                        </div>
                        <div className="phnno">
                            <a href="tel:9029437135"><img className="phnsymb" src={phn} alt=""></img><p className="numbr">+91 90294 37135</p></a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;